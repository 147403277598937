
// 2019-04-23

import Chart from "chart.js"

const charts = {}
const userCountElement = document.querySelector("#users_count")
const testsCompletedElement = document.querySelector("#tests_completed_count")
const categoriesSections = document.querySelectorAll(".categories")

const colors = {
  red: {
    background: 'var(--grey-200)',
    border: 'var(--red)'
  },
  yellow: {
    background: 'var(--grey-200)',
    border: 'var(--yellow)'
  },
  green: {
    background: 'var(--grey-200)',
    border: 'var(--green)'
  },
  blue: {
    background: 'var(--grey-200)',
    border: 'var(--red)'
  },
}

const mappingColors = {
  low: 'red',
  medium: 'yellow',
  high: 'green',
  answer_no: 'red',
  answer_maybe: 'yellow',
  answer_yes: 'green',
  numbers: 'blue'
}


const optionsBarChart = {
    scales: {
      xAxes: [{
          stacked: true
      }],
      yAxes: [{
          stacked: true,
          ticks: {
            precision: 0,
            min: 0
          }
      }]
    },
    tooltips: {
      mode: 'index',
      intersect: false
    },
    legend: {
      display: false,
    }
}

const initChartUserCount = () => {
  charts.userCount = new Chart(userCountElement.querySelector("canvas"), {
      type: 'bar',
      options: optionsBarChart
  })
  fetchUserCount()

  userCountElement.querySelectorAll('input').forEach((input) => {
    input.addEventListener('change', (e) => { fetchUserCount() })
  })

}

const initChartTestsCompletedCount = () => {
  charts.testCompleted = new Chart(testsCompletedElement.querySelector("canvas"), {
      type: 'bar',
      options: optionsBarChart
  })
  fetchTestsCompletedCount()

  testsCompletedElement.querySelectorAll('input').forEach((input) => {
    input.addEventListener('change', (e) => { fetchTestsCompletedCount() })
  })
  testsCompletedElement.querySelectorAll('select').forEach((select) => {
    select.addEventListener('change', (e) => { fetchTestsCompletedCount() })
  })
}

const initCategories = () => {
  categoriesSections.forEach((categoriesSection) =>  {
    charts[categoriesSection.id] = new Chart(categoriesSection.querySelector("canvas"), {
      type: 'bar',
      options: optionsBarChart
    })
    fetchCategories(categoriesSection)
    categoriesSection.querySelectorAll('input').forEach((input) => {
      input.addEventListener('change', (e) => { fetchCategories(categoriesSection) })
    })
  });


}

const updateTotalCount = (statElement, datasets) => {
  let html = ''
  let total = 0
  if (datasets.length > 1) {
    const strs = []
    datasets.forEach((dataset) => {
      total += dataset.total
      strs.push(`<span class="color-${mappingColors[dataset.name]} ">${dataset.name} (${dataset.total})</span>`)
    })
    html = strs.join(' + ') + ` = ${total}`
  } else {
    html = datasets[0].total
  }
  statElement.querySelector('.total .values').innerHTML = html
}

const updateDataChartBar = (chartJs, datasets) => {

  const datasetsParams = Object.keys(datasets).map((elem) => {
    const dataset = datasets[elem]
    const color = mappingColors[dataset.name]
    return  {
      data:  Object.keys(dataset.data).map((key) => { return dataset.data[key] }),
      fill: true,
      backgroundColor: colors[color].background,
      borderColor: colors[color].border,
      borderWidth: 1
    }
  })

  chartJs.data = {
    labels: Object.keys(datasets[0].data).map((elem) => { return elem }),
    datasets: datasetsParams
  }
  chartJs.update()
}

const timeScope = (chartElement) => {
  const dateStart = chartElement.querySelector("input[name=date_start]").value
  const dateEnd = chartElement.querySelector("input[name=date_end]").value
  return `dateStart=${dateStart}&dateEnd=${dateEnd}`
}

const dataSort = (chartElement) => {
  const ds = chartElement.querySelector(".sort_data input[type=radio]:checked").value
  return `dataSort=${ds}`
}


const fetchUserCount = () => {
  const confirmed = userCountElement.querySelector("[name=user_confirmed]:checked").value

  fetch(`/admin/dashboard/users_count?${timeScope(userCountElement)}&${dataSort(userCountElement)}&confirmed=${confirmed}`).then((response) => { return response.json() }).then((data) => {
    updateDataChartBar(charts.userCount, data.datasets)
    updateTotalCount(userCountElement, data.datasets)
  })
}

const fetchTestsCompletedCount = () => {
  const userTestScope = testsCompletedElement.querySelector("[name=user_test_scope]:checked").value
  const autodiagScope = document.querySelector('#tests_completed_autodiag').value

  fetch(`/admin/dashboard/tests_completed_count?autodiag_identifier=${autodiagScope}&${timeScope(testsCompletedElement)}&${dataSort(testsCompletedElement)}&userTestScope=${userTestScope}`).then((response) => { return response.json() }).then((data) => {
    updateDataChartBar(charts.testCompleted, data.datasets)
    updateTotalCount(testsCompletedElement, data.datasets)
  })
}

const fetchCategories = (categoriesSection) => {
  const inputScope = categoriesSection.querySelector(".date_range input:checked")
  const categoryId = categoriesSection.querySelector(".radio_category:checked").value

  fetch(`/admin/dashboard/categories/${categoryId}?${timeScope(categoriesSection)}&${dataSort(categoriesSection)}`).then((response) => { return response.json() }).then((data) => {
    updateDataChartBar(charts[categoriesSection.id], data.datasets)
    updateTotalCount(categoriesSection, data.datasets)
  })
}

const initDashboard = () => {
  if (userCountElement == null)
    return

  initChartUserCount()
  initChartTestsCompletedCount()
  initCategories()
}

export default initDashboard
