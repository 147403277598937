const initAlert = () => {

  const alert = document.querySelector('.alert-dismissible');

  if (alert == null)
    return

  setTimeout(function(){ alert.classList.add('invisible'); }, 8000);

}

export default initAlert
