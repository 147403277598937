import Chart from "chart.js"

Chart.defaults.global.defaultFontColor='#333333';

const initPdfView = () => {
  const reportElem = document.querySelector('.report')

  if (reportElem != null && reportElem.dataset.pdfView == "true") {
    Chart.defaults.global.animation.duration = 0
  }
}

const shortLabel = (label) => {
  const windowSize = document.querySelector('.graph-center').offsetWidth
  let maxLabel

  if (windowSize > 700)
    maxLabel = 30
  else if (windowSize > 500)
    maxLabel = 20
  else
    maxLabel = 10

  const arr = []
  label.split(' ').forEach((word, i) => {
    if (i == 0)
      arr.push(word)
    else {
      const last_element = arr[arr.length - 1]
      if (last_element.length + word.length <= maxLabel)
        arr[arr.length - 1] = `${last_element} ${word}`
      else
        arr.push(word)
    }
  })
  return arr
}

const initDonut = (doughnutElem) => {
  const data = {
    datasets: [{
        data: [doughnutElem.dataset.note, 100 - doughnutElem.dataset.note],
        backgroundColor: [doughnutElem.dataset.color]
    }]
  }

  const options = {tooltips: {enabled: false}}

  new Chart(doughnutElem, {
      type: 'doughnut',
      data: data,
      options: options
  })

}


const initDonuts = () => {
  document.querySelectorAll('.doughnut').forEach(initDonut)
}

const initRadar = () => {
  const ctx = document.querySelector('#radar-chart')
  if (ctx == null)
    return

  const stats = JSON.parse(ctx.dataset.stats)
  const values = Object.keys(stats).map((key) => { return stats[key] })
  const data = {
    labels: Object.keys(stats).map(shortLabel),
    datasets: [{
        data: values, // Object.values(stats) => ERROR IN IE with Object.values,
        backgroundColor: "rgba(15, 242, 178, 0.50)",
        borderColor: "rgb(15, 242, 178)",
        borderWidth: 2,
    }]
  }

  const options = {
    legend: {
      display: false,
    },
    scale: {
      ticks: {
        max: 100,
        min: 0,
        stepSize: 25
      },
      gridLines: {
        color: 'rgb(125, 125, 125)'
      }
    }
  }

  new Chart(ctx, {
      type: 'radar',
      data: data,
      options: options
  })
}

const initChart = () => {
  initPdfView()
  initRadar()
  initDonuts()
}

export default initChart
