import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.min.css'

const initSelect2 = () => {
  $('.select2').select2()
  $('.select2-tags').select2({
    tags: true,
    placeholder: "Type one email and press Enter."
  })
}

export default initSelect2
