import './polyfills.js'
import './lr_messages.js'
import initSelect2 from './init_select2.js'
import initStatesInput from "./init_states_input"
import initFormAnswers from './init_form_answers.js'
import initAutodiagTranslate from './init_autodiag_translate.js'
import initDashboard from './init_dashboard.js'
import initLinkTableUsers from './init_link_table_users.js'
import initReport from './init_report.js'
import initQuill from './init_quill.js'
import initAlert from './init_alert.js'
import initTooltips from './init_tooltips.js'
import initPhoneInput from './init_phone_input.js'
import Popper from 'popper.js'
import 'bootstrap'

initSelect2()
initStatesInput()
initFormAnswers()
initAutodiagTranslate()
initReport()
initLinkTableUsers()
initQuill()
initAlert()
initDashboard()
initTooltips()
initPhoneInput()
