import $ from 'jquery'

const progressBar     = document.querySelector('.progress-bar')
const nextStepButton  = document.querySelector('.next-step')

const currentCategoryCompleted = () => {
  return document.querySelectorAll('.form_answer input[type=radio]:checked').length == document.querySelectorAll('.form_answer').length
}

const updateStateNextStep = () => {
  if (currentCategoryCompleted()) {
    nextStepButton.classList.remove("disabled")
    $('.next_category_tooltips').tooltip('disable')
  } else {
    nextStepButton.classList.add("disabled")
    $('.next_category_tooltips').tooltip('enable')
  }
}

const ajaxSuccess = (data) => {
  const percent = `${data.progress}%`

  progressBar.style.width = percent
  progressBar.innerHTML = percent
  updateStateNextStep()
}

const synchroServer = (answerId, answerValue) => {
  const options = {
    method: 'PATCH',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({answer: {value: answerValue}}),
    credentials: 'same-origin'
  }
  fetch(`/answers/${answerId}`, options).then((response) => { return response.json() }).then(ajaxSuccess)
}

const eventAnswerElementChange = (e) => {
  const answerElement = e.currentTarget
  const formElem = answerElement.closest('form')
  synchroServer(formElem.dataset.answerId, answerElement.value)
}

const initAnswerElement = (answerElement) => {
  answerElement.addEventListener('change', eventAnswerElementChange)
}

const initFormAnswers = () => {
  const answerButtonElems = document.querySelectorAll('.form_answer input[type="radio"]')
  if (answerButtonElems.length == 0) { return }

  updateStateNextStep()
  answerButtonElems.forEach(initAnswerElement)
}

export default initFormAnswers;


