const translateRowElements = document.querySelectorAll('.row-autodiag-translate-field')

const refreshProperties = (translateRowElement) =>  {
  const saveButton = translateRowElement.querySelector('.save')
  const undoButton = translateRowElement.querySelector('.undo')
  const editableElement = translateRowElement.querySelector('.editable')
  const value = editableElement.tagName == "DIV" ? editableElement.innerHTML : editableElement.value


  if (value == editableElement.dataset.ref)  {
    undoButton.classList.remove('undo-show')
    saveButton.classList.remove('save-waiting')
  }
  else {
    undoButton.classList.add('undo-show')
    saveButton.classList.add('save-waiting')
  }
  saveButton.classList.remove('save-success')
}

const ajaxSuccess = (translateRowElement, data) => {
  const saveButton = translateRowElement.querySelector('.save')
  const undoButton = translateRowElement.querySelector('.undo')
  const editableElement = translateRowElement.querySelector('.editable')

  editableElement.dataset.ref = editableElement.tagName == "DIV" ? editableElement.innerHTML : editableElement.value

  undoButton.classList.remove('undo-show')
  saveButton.classList.remove('save-waiting')
  saveButton.classList.add('save-success')
}

const synchroServer = (options, translateRowElement) => {
  console.log(options)
  const data = {[options.attrModel]: {[options.attrName]: options.value}}
  const optionsRequest = {
    method: 'PATCH',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
    credentials: 'same-origin'
  }
  fetch(options.url, optionsRequest)
    .then((response) => {
      if (response.ok)
        return response.json()
      lrError("saving translation failed")
    })
    .then(ajaxSuccess.bind(null, translateRowElement))
}

const saveClicked = (e) => {
  if (!e.target.parentNode.classList.contains('save-waiting'))
    return ;

  const translateRowElement = e.target.closest('.row-autodiag-translate-field')
  const editableElement = translateRowElement.querySelector('.editable')

  synchroServer({
    url: translateRowElement.dataset.url,
    attrName: translateRowElement.dataset.attrName,
    attrModel: translateRowElement.dataset.attrModel,
    value: editableElement.tagName == "DIV" ? editableElement.innerHTML : editableElement.value
  }, translateRowElement)
}

const undoClicked = (e) => {
  const translateRowElement = e.target.closest('.row-autodiag-translate-field')
  const editableElement = translateRowElement.querySelector('.editable')

  if (editableElement.tagName == "DIV")
    editableElement.innerHTML = editableElement.dataset.ref
  else
    editableElement.value = editableElement.dataset.ref

  refreshProperties(translateRowElement)
}

const initSaveButton = (translateRowElement) => {
  const saveButton = translateRowElement.querySelector('.save')

  saveButton.addEventListener('click', saveClicked)
}

const initUndoButton = (translateRowElement) => {
  const undoButton = translateRowElement.querySelector('.undo')

  undoButton.addEventListener('click', undoClicked)
}

const editableChanged = (e) => {
  refreshProperties(e.target.closest('.row-autodiag-translate-field'))
}

const initContentEditable = (translateRowElement) => {
  const editableElement = translateRowElement.querySelector('.editable')
  editableElement.addEventListener('input', editableChanged)
}

const initTranslateRowElement = (translateRowElement) => {
  initContentEditable(translateRowElement)
  initSaveButton(translateRowElement)
  initUndoButton(translateRowElement)
}

const initOnbeforeunload = () => {
  window.onbeforeunload = (e) => {
    if (document.querySelector('.save-waiting'))
      return ""
  }
}

const initAutodiagTranslate = () => {
  if (translateRowElements.length == 0)
    return

  translateRowElements.forEach(initTranslateRowElement)
  initOnbeforeunload()
}

export default initAutodiagTranslate
