import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.min.css'

const countryCodeFromDomain = () => {
  const forceDomainMetaTag = document.querySelector("meta[name=force_domain][content]")
  const url = forceDomainMetaTag ? forceDomainMetaTag.getAttribute('content') : window.location.hostname
  return url.split('.')[0].split('-')[0].replace(/^ci$/, "cl")
}


const initPhoneInput = () => {
  const input = document.querySelector("#user_phone");

  if(input == null)
    return

  intlTelInput(input, {
    nationalMode: false,
    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.8/js/utils.js',
    initialCountry: countryCodeFromDomain(),
    customContainer: "d-block"
  })
}

export default initPhoneInput
