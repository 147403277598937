const updateStates = (event) => {
  const countrySelect = event.target
  const states = JSON.parse(countrySelect.options[countrySelect.selectedIndex].dataset.states)

  const stateSelect = countrySelect.closest("form").querySelector("#user_state")
  if (!stateSelect) return

  const stateFormGroup = stateSelect.closest(".form-group") || stateSelect
  if (!stateSelect || !stateFormGroup) return

  // Set Options
  if (!event.initialUpdate) {
    stateSelect.innerHTML = ""
    if (states.length) {
      states.forEach(state => stateSelect.add(new Option(state)))
    }
  }

  // Show or Hide
  if (states.length) {
    stateFormGroup.style.display = "block"
  } else {
    stateFormGroup.style.display = "none"
  }
}

export default () => {
  const countrySelect = document.querySelector("#user_country_id")
  if (!countrySelect) return

  countrySelect.addEventListener("change", updateStates)
  updateStates({ initialUpdate: true, target: countrySelect })
}
