import initChart from './init_chart.js'

const linkEmailElem = document.querySelector('#send-report-email')
const reportElem = document.querySelector('.report')

const sendAjax = () => {
  const optionsRequest = {
    method: 'POST',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'same-origin'
  }

  fetch(linkEmailElem.href, optionsRequest)
    .then((response) => {
      if (!response.ok)
        throw Error(response.statusText)
      return response.json()
    })
    .then((response) => { lrSuccess(response.message) })
    .catch((message) => { lrError("send email failed") })
    .finally(() => { linkEmailElem.classList.remove('disabled') })
}

const initSendReportLink = () => {
  if (linkEmailElem == null)
    return

  linkEmailElem.addEventListener('click', (e) => {
    e.preventDefault()
    linkEmailElem.classList.add('disabled')
    sendAjax()
  })
}

const initReport = () => {
  if (reportElem != null) {
    initChart()
    initSendReportLink()
  }
}

export default initReport
