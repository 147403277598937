const tdClick = (e) => {
  const url = e.currentTarget.closest('tr').dataset.url
  window.location = url
}

const bindTdClick = (td) => {
  td.addEventListener('click', tdClick)
}

const initLinkTableUsers = () => {
  document.querySelectorAll('.table-users td:not(.td-actions)').forEach(bindTdClick)
}

export default initLinkTableUsers
