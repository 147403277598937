import iziToast from 'izitoast'
import 'izitoast/dist/css/iziToast.css'

window.lrError = (message) => {
  iziToast.error({
      title: 'Something wrong',
      message: message,
      position: 'topRight',
      timeout: 8000
  })
}

window.lrSuccess = (message) => {
  iziToast.success({
      message: message,
      position: 'topRight',
      timeout: 8000
  })
}

