import Quill from 'quill'
import 'quill/dist/quill.snow.css'

const formElem = document.querySelector('.edit_email_template')
const contentField = document.querySelector('#email_template_content')
let quill

const submitFormEvent = (e) => {
  contentField.value = quill.root.innerHTML
}

const initQuill = () => {
  if (formElem == null)
    return

  quill = new Quill('#text-editor', {theme: 'snow'})
  formElem.addEventListener('submit', submitFormEvent)
}

export default initQuill
